// Upload Prescriptions for Flexmed
import React, { useContext } from "react"

import Layout from "layout"
import Upload from "../../../Upload"

import FlexmedCashlessCart from "../../Cart"
import guidelines from "./utils/uploadGuidelines.json"
import { FlexmedCashlessContext } from "../../FlexmedContext/FlexmedCashlessContext"

import useFlexmedImages from "./hooks/useFlexmedImages"

const FlexmedRxUpload = ({ pageContext }) => {
  const { flexmedCashlessState, flexmedCashlessDispatch } = useContext(
    FlexmedCashlessContext
  )
  const data = useFlexmedImages()
  const fileUploadPrescription = data?.prescription?.childImageSharp?.fixed
  const MAX_FILE_SIZE_IN_BYTES = 4000000
  const MAX_FILE_COUNT = 6

  return (
    <Layout {...pageContext?.module} currentStep={1} totalSteps={4}>
      <Upload
        pageContext={pageContext}
        icon={fileUploadPrescription}
        maxFileCount={MAX_FILE_COUNT}
        maxFileSize={MAX_FILE_SIZE_IN_BYTES}
        guidelines={guidelines}
        dropzoneLabel="Upload Prescription"
        cartContainer={
          <FlexmedCashlessCart
            open={{ documentsCard: true, medicinesCard: false }}
            flexmedCashlessState={flexmedCashlessState}
            flexmedCashlessDispatch={flexmedCashlessDispatch}
            pageContext={pageContext}
          />
        }
        moduleState={flexmedCashlessState}
        documents={flexmedCashlessState.documents}
        moduleDispatch={flexmedCashlessDispatch}
        module="cashless"
      />
    </Layout>
  )
}

export default FlexmedRxUpload
